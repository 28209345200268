var _ = require('lodash');
const Snow = (canvas, count, options) => {
  const ctx = canvas.getContext('2d')
  const snowflakes = []

  const add = item => snowflakes.push(item(canvas))

  const update = () => _.forEach(snowflakes, el => el.update())

  const resize = () => {
    ctx.canvas.width = canvas.offsetWidth
    ctx.canvas.height = canvas.offsetHeight

    _.forEach(snowflakes, el => el.resized())
  }

  const draw = () => {
    ctx.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight)
    _.forEach(snowflakes, el => el.draw())
  }
  
  const events = () => {
    window.addEventListener('resize', resize)
  }

  const loop = () => {
    draw()
    update()
    animFrame(loop)
  }

  const init = () => {
    _.times(count, () => add(canvas => SnowItem(canvas, null, options)))
    events()
    loop()
  }

  init(count)
  resize()

  return { add, resize }
}

const defaultOptions = {
  color: 'orange',
  radius: [30.0, 50.0],
  speed: [0.1, 0.2],
  wind: [0.3, 0.4],
  alpha: [0.2, 0.4]
}

const SnowItem = (canvas, drawFn = null, opts) => {
  const options = { ...defaultOptions, ...opts }
  const { radius, speed, wind, color, alpha } = options
  const params = {
    color,
    x: _.random(-canvas.offsetWidth, 0),
    y: _.random(0, canvas.offsetHeight/10),
    radius: _.random(...radius),
    speed: _.random(...speed),
    wind: _.random(...wind),
    alpha: _.random(...alpha),
    isResized: false
  }
  let alphaSpace = 0.003;
  const ctx = canvas.getContext('2d')
  
  const updateData = () => {
    params.x = _.random(-canvas.offsetWidth, 0),
    params.y = _.random(0, canvas.offsetHeight/10)
  }
  
  const resized = () => params.isResized = true

  const drawDefault = () => {
    const gradient = ctx.createRadialGradient(params.x, params.y, params.radius/2, params.x, params.y, params.radius);
    gradient.addColorStop(0, 'rgba(255,255,255,1)');
    gradient.addColorStop(0.8, 'rgba(255,255,255,0)');

    ctx.beginPath()
    ctx.globalAlpha = params.alpha
    ctx.arc(params.x, params.y, params.radius, 0, 2 * Math.PI)
    // ctx.fillStyle = params.color
    ctx.fillStyle = gradient
    ctx.fill()
    ctx.closePath()
  }

  const draw = drawFn
    ? () => drawFn(ctx, params)
    : drawDefault

  const translate = () => {
    params.x += params.wind
    params.y += params.speed
    params.alpha += _.random(alphaSpace / 3, alphaSpace)
    if(params.alpha <= 0.2 || params.alpha >= 0.4) {
      alphaSpace = -alphaSpace
    }
    // params.radius += 0.02
  }

  const onDown = () => {
    if (params.y > canvas.offsetHeight + params.radius || params.x > canvas.offsetWidth + params.radius ) {
      if (params.isResized) {
        updateData()
        params.isResized = false
      } else {
        params.x = _.random(-canvas.offsetWidth, 0)
        params.y = _.random(0, canvas.offsetHeight/10)
        params.radius = _.random(...radius)
        params.alpha = _.random(...alpha)
      }
    } 
  }

  const update = () => {
    translate()
    onDown()
  }

  return {
    update,
    resized,
    draw
  }
}

const animFrame = window.requestAnimationFrame ||
                  window.mozRequestAnimationFrame ||
                  window.webkitRequestAnimationFrame ||
                  window.msRequestAnimationFrame;

export { Snow };