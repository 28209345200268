import barba from '@barba/core';
import "preline";

import Swiper, { EffectFade, Autoplay, Navigation, Pagination, Grid } from "swiper";
import "swiper/swiper-bundle.min.css";
import { Snow } from './class/snow';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

document.getElementById("navbar-collapse-basic").addEventListener('open.hs.collapse', (evt) => {
  document.getElementsByClassName('header')[0].classList.add('open');
})

document.getElementById("navbar-collapse-basic").addEventListener('hide.hs.collapse', (evt) => {
  document.getElementsByClassName('header')[0].classList.remove('open');
})

barba.init({
  transitions: [{
    beforeOnce: () => {
      gsap.set('.barba-cover', {y: 0})
      gsap.set('.barba-cover .logo', {opacity: 0})
    },
    async once() {
      let tl = gsap.timeline()
      await 
        tl
          .to('.barba-cover .logo', {opacity: 1, duration: 1})
          .to({}, {duration: 1})
          .to('.barba-cover .logo', {opacity: 0, duration: 1})
    },
    afterOnce: () => {
      gsap.to('.barba-cover', {y:"-100%", duration: 0.6})
    },
    async leave(data) {
      await 
        gsap.timeline()
          .fromTo('.barba-cover', {y: "100%"}, {y: 0, duration: 0.6})
          .call(()=>{ 
            if(data.current != data.next) {
              document.documentElement.scrollTop = 0;
            }

            ScrollTrigger.getAll().forEach(function (trigger) {
              trigger.kill();
            });
      
            [].forEach.call(document.getElementsByClassName('swiper'), (el) => {
              el.swiper.destroy(false);
            });
          })
          .set(data.current.container, { autoAlpha: 0, height: 0 })
          .to({}, {duration: 1})
    },
    async enter(data) {
      initGsap();
      await gsap.to('.barba-cover', {y:"-100%", duration: 0.6})
    }
  }]
});

window.addEventListener('scroll', function() {
  let header = document.getElementsByClassName('header')[0];
  let logo = document.getElementsByClassName('wrap-logo')[0];
  if (document.documentElement.scrollTop > (window.innerHeight - logo.offsetHeight )) {
    header.classList.add('bg');
  } else {
    header.classList.remove('bg');
  }
});

initGsap();
function initGsap() {
  new Swiper(".swiper-fadeZoomOut", {
    modules: [EffectFade, Autoplay],
    allowTouchMove: false,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 5000,
    on: {
      init: function() {
        const currentImg = this.slides[this.activeIndex].querySelector("img");
        gsap.fromTo(currentImg, {
          scale: 1.1
        }, {
          scale: 1,
          duration: 10
        });
      },
      slideChangeTransitionStart: function (i) {
        const currentImg = this.slides[this.activeIndex].querySelector("img");
        gsap.fromTo(currentImg, {
          scale: 1.1
        }, {
          scale: 1,
          duration: 15
        });
      },
    },
  });

  new Swiper(".swiper-vlist", {
    modules: [Navigation, Grid],
    slidesPerView: 1,
    grid: {
      rows: 3,
    },
    spaceBetween: 20,
    navigation: {
      nextEl: '.outside.swiper-button-next',
      prevEl: '.outside.swiper-button-prev',
    },
  });

  new Swiper(".swiper-hlist", {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 80,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
    },
    navigation: {
      nextEl: '.outside.swiper-button-next',
      prevEl: '.outside.swiper-button-prev',
    },
  });

  [].forEach.call(
    document.querySelectorAll(".swiper-loop"),
    function (slider, index, arr) {
      let data = slider.getAttribute("data-swiper") || "{}";
      let dataOptions = {};
  
      if (data) {
        dataOptions = JSON.parse(data);
      }
  
      slider.options = Object.assign(
        {},
        {
          modules: [Autoplay],
          loop: true,
          // freeMode: true,
          centeredSlides: true,
          allowTouchMove: true,
          spaceBetween: 10,
          slidesPerView: 1.5,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          speed: 2000,
          breakpoints: {
            768: {
              spaceBetween: 30,
            },
          },
        },
        dataOptions
      );
  
      let loopSwiper = new Swiper(slider, slider.options);
      // 當滑鼠進入 Swiper 容器時停止自動播放
      loopSwiper.el.addEventListener('mouseenter', function () {
        loopSwiper.autoplay.stop();
      });

      // 當滑鼠離開 Swiper 容器時重新啟動自動播放
      loopSwiper.el.addEventListener('mouseleave', function () {
        loopSwiper.autoplay.start();
      });
    }
  );

  gsap.utils.toArray("[data-aos^=fade]").forEach((ele) => {
    const direction = ele.dataset.aos.split("-")[1] || "null",
      delay = ele.dataset.delay || 0,
      duration = ele.dataset.duration || 1,
      proxy = {
        up: [0, 50],
        down: [0, -50],
        left: [10, 0],
        right: [-10, 0],
        null: [0, 0],
      };
    gsap.from(ele, {
      scrollTrigger: {
        trigger: ele,
        start: "top 80%",
        once: "true",
        // toggleActions: 'play none none reverse',
        // markers: true,
      },
      duration: parseFloat(duration),
      delay: delay,
      opacity: 0,
      xPercent: proxy[direction][0],
      yPercent: proxy[direction][1],
    });
  });

  gsap.utils.toArray("[data-aos^=inset]").forEach((ele) => {
    const direction = ele.dataset.aos.split("-")[1] || "null",
      delay = ele.dataset.delay || 0,
      duration = ele.dataset.duration || 2,
      proxy = {
        up: ['100%', '0%'],
        down: ['0%', '100%'],
        null: ['0%', '0%'],
      };
    gsap.fromTo(ele, {
      clipPath: "inset("+proxy[direction][0]+" 0% "+proxy[direction][1]+" 0%)"
    }, {
      scrollTrigger: {
        trigger: ele,
        start: "top 80%",
        once: "true",
        // toggleActions: 'play none none reverse',
        // markers: true,
      },
      duration: parseFloat(duration),
      delay: delay,
      clipPath: "inset(0% 0% 0% 0%)",
      onComplete: () => {
        gsap.set(ele, { clearProps: "all" });
      }
    });
  });

  Array.prototype.forEach.call(
    document.querySelectorAll(".rays"),
    function (eles) {
      Snow(eles, 10, { color: '#fffde4' })
    }
  );
  Array.prototype.forEach.call(
    document.querySelectorAll("video"),
    function (eles) {
      eles.play();
    }
  );
}